
<app-loading [hidden]="load"></app-loading>

<header id="masthead" class="header ttm-header-style-classicinfo">
    <div class="ttm-fbar-main-w ttm-fbar-position-right">
        <div class="ttm-fbar-box-w ttm-textcolor-white ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes">
            <span class="ttm-fbar-btn">
                <a href="javascript:void(0)" class="ttm-fbar-btn-link">
                    <span class="ttm-fbar-open-icon"><i class="fa fa-bars"></i></span>
            <span class="ttm-fbar-close-icon"><i class="fa fa-times"></i></span>
            </a>
            </span>
            <div class="ttm-fbar-bg-layer ttm-bg-layer"></div>
            <div class="ttm-fbar-content-wrapper">
                <div class="ttm-fbar-box">
                    <aside id="ttm_widget_team_search-2" class="widget-even widget-4 widget ttm_widget_team_search">
                        <div class="team-search-form-w">
                            <div class="ttm-team-search-title">
                                <h2>{{contactUs}}</h2>
                            </div>
                        </div>
                    </aside>
                    <aside id="enhancedtextwidget-11" class="widget-even widget-6 widget widget_text enhanced-text-widget">
                        <div class="textwidget widget-text">
                            <div class="featured-icon-box left-icon icon-align-top">
                                <div class="featured-icon">
                                    <div class="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                                        <i class="fa fa-phone"></i>
                                    </div>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title">
                                        <h5>{{ph1}} </h5>
                                        <h5>{{ph2}} </h5>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </aside>
                    <aside id="enhancedtextwidget-11" class="widget-even widget-6 widget widget_text enhanced-text-widget">
                        <div class="textwidget widget-text">
                            <div class="featured-icon-box left-icon icon-align-top">
                                <div class="featured-icon">
                                    <div class="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                                        <i class="fa fa-map-marker"></i>
                                    </div>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title">
                                        <h5>{{address_}} </h5>
                                        <h5>{{address2_}} </h5>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </aside>
                    <aside id="enhancedtextwidget-11" class="widget-even widget-6 widget widget_text enhanced-text-widget">
                        <div class="textwidget widget-text">
                            <div class="featured-icon-box left-icon icon-align-top">
                                <div class="featured-icon">
                                    <div class="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                                        <i class="fa fa-envelope-o"></i>
                                    </div>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title">
                                        <h5>{{email__}} </h5>
                                        <h5>{{email2__}} </h5>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </div>

    <div class="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
        <div class="container">
            <div class="ttm-topbar-content">
                <ul class="top-contact text-left">
                    <li><i class="themifyicon ti-location-pin"></i>{{address_}} </li>
                    <!-- <li><i class="themifyicon ti-timer"></i>Saturday - Thursday , Friday holiday</li> -->

                </ul>
                <div class="topbar-right text-right">
                    <div class="ttm-social-links-wrapper list-inline">
                        <ul class="social-icons">
                            <li *ngIf="facebook">
                                <a href="{{facebook}}" target="_blank">
                                    <i class="fa fa-facebook"></i></a>
                            </li>
                            <li *ngIf="twitter">
                                <a href="{{twitter}}" target="_blank"><i class="fa fa-twitter"></i></a>
                            </li>
                            <li *ngIf="pinterest">
                                <a href="{{pinterest}}" target="_blank"><i
                                        class="fa fa-pinterest-p"></i></a>
                            </li>
                            <li *ngIf="instgram">
                                <a href="{{instgram}}" target="_blank"><i
                                        class="fa fa-instagram"></i></a>
                            </li>
                            <li *ngIf="linkdin">
                                <a href="{{linkdin}}" target="_blank"><i
                                        class="fa fa-linkedin"></i></a>
                            </li>
                            <li *ngIf="youtube">
                                <a href="{{youtube}}" target="_blank"><i
                                        class="fa fa-youtube"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="ttm-header-wrap">
        <div id="ttm-stickable-header-w" class="ttm-stickable-header-w ttm-bgcolor-white clearfix">
            <div id="site-header-menu" class="site-header-menu">
                <div class="site-header-menu-inner ttm-stickable-header">
                    <div class="container">
                        <div class="site-branding">
                            <a class="home-link"  title="Alshamelah" rel="home">
                                <img id="logo-img" class="img-center" src="{{Image}}" alt="logo-img">
                            </a>
                        </div>
                        <div id="site-navigation" class="site-navigation">
                            <div class="ttm-header-icons ">
                            </div>
                            <div class="ttm-menu-toggle">
                                <input type="checkbox" id="menu-toggle-form" />
                                <label for="menu-toggle-form" class="ttm-menu-toggle-block">
                                    <span class="toggle-block toggle-blocks-1"></span>
                                    <span class="toggle-block toggle-blocks-2"></span>
                                    <span class="toggle-block toggle-blocks-3"></span>
                                </label>
                            </div>
                            <nav id="menu" class="menu">
                                <ul class="dropdown">
                                    <li class="active">
                                        <a routerLink="/home">{{home}}</a>
                                    </li>
                                    <li>
                                        <a routerLink="/about">{{aboutUs}}</a>
                                        <ul>
                                            <li *ngFor="let item of arrayAboutUs"><a routerLink="/about/{{item.postID}}">{{item.postTitle}}</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a routerLink="/news">{{News}}</a>
                                    </li>
                                    <li><a>{{Partners}}</a>
                                        <ul>
                                            <li><a routerLink="/partners">{{Partners}}</a></li>
                                            <li><a routerLink="/clients">{{Clients}}</a></li>
                                        </ul>
                                    </li>
                                    <!-- <li><a routerLink="clients">{{Clients}}</a></li> -->
                                    <li>
                                        <a routerLink="/ProductCategories">{{BusinessLine}}</a>
                                    </li>
                                    <li><a routerLink="/Quality">{{Quality}}</a></li>

                                    <li>
                                        <a routerLink="/contactUs">{{contactUs}}</a>
                                    </li>
                                    <!-- <li> -->
                                        <!-- <a href="#"><i style="font-size: 24px;" class="fa fa-language" aria-hidden="true"></i></a> -->
                                        <!-- <ul> -->
                                            <li *ngIf="!varLang"><a style="cursor: pointer;" (click)="lang('Ar')">عربى</a></li>
                                            <li *ngIf="varLang"><a style="cursor: pointer;" (click)="lang('En')">English</a></li>
                                        <!-- </ul> -->
                                    <!-- </li> -->
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="ttm-content-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="ttm-info-widget">

                        <div class="header-widget" *ngFor="let item of arrayFeaturs">
                            <div class="header-icon">
                                <i class="fa ">
                               <a routerLink="/Features">   <img src="{{imgUrl}}{{item.postImage}}" style="height: 52px;  border-color: #fff;
                                    border-style: outset;
                                    border-width: 2px;" /></a>
                                </i>
                            </div>
                            <div class="header-content">
                                <h3><a routerLink="/Features"> 
                                    {{item.postTitle}}
                                </a></h3>
                                <p>{{item.postSubTitle}}</p>
                            </div>
                        </div>

                        <!-- <div class="header-widget">
                            <div class="header-icon">
                                <i class="fa fa-handshake-o"></i>
                            </div>
                            <div class="header-content">
                                <h3>
                                    Get the best
                                </h3>
                                <p>We offer the best</p>
                            </div>
                        </div> -->

                    </div>
                    <div class="ttm-contact">
                        {{email__}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>


<router-outlet (activate)="onActivate($event)"></router-outlet>

<footer class="footer widget-footer clearfix">

    <div class="second-footer ttm-textcolor-white footer-color">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                    <div class="widget widget_text clearfix">
                        <h3 class="widget-title">{{AboutTitle}}</h3>
                        <div class="textwidget widget-text">
                            <p [innerText]="AboutSub"></p>
                            <!-- <p [innerHTML]="AboutDetials"></p> -->
                             
                            <br><br>
                            <div class="social-icons social-hover">
                                <ul class="list-inline">
                                    <li class="social-facebook"><a class="tooltip-top" target="_blank" href="{{facebook}}" data-tooltip="Facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li class="social-twitter"><a class="tooltip-top" target="_blank" href="{{twitter}}" data-tooltip="Twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li class="social-linkedin"><a class=" tooltip-top" target="_blank" href="{{linkdin}}" data-tooltip="LinkedIn"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    <li>
                                        <a href="{{pinterest}}" class=" tooltip-top" target="_blank" data-tooltip="Pinterest"><i
                                                class="fa fa-pinterest-p"></i></a>
                                    </li>
                                    <li>
                                        <a *ngIf="instgram" href="{{instgram}}" class=" tooltip-top" target="_blank" data-tooltip="Instagram"><i
                                                class="fa fa-instagram"></i></a>
                                    </li>
                                    <li>
                                        <a href="{{youtube}}" class=" tooltip-top" target="_blank" data-tooltip="Youtube"><i
                                                class="fa fa-youtube"></i></a>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                    <div class="widget widget_nav_menu clearfix">
                        <h3 class="widget-title">{{aboutUs}}</h3>
                        <ul id="menu-footer-quick-links">
                            <li *ngFor="let item of arrayAboutUs"><a routerLink="/about/{{item.postID}}">{{item.postTitle}}</a></li>

                        </ul>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                    <div class="widget style2 widget-out-link clearfix">
                        <h3 class="widget-title">{{News}}</h3>
                        <ul class="widget-post ttm-recent-post-list">
                            <li *ngFor="let item of arrayNews">
                                <a href="single-blog.html"><img src="{{imgUrl}}{{item.postImage}}" alt="post-img"></a>
                                <span class="post-date">{{item.date1 | date: "y " }}</span>
                                <a routerLink="/news/{{item.postID}} ">{{item.postTitle}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                    <div class="widget widget_nav_menu menu-footer-services-menu clearfix">
                           <h3 class="widget-title">  {{titleCompany}} </h3>
                        <ul id="menu-footer-services-menu" class="menu">


                            <li>
                                <a routerLink="/home">{{home}}</a>
                            </li>

                            <li>
                                <a routerLink="/news">{{News}}</a>
                            </li>
                            <li><a routerLink="/Quality">{{Quality}}</a></li>
                            <li>
                                <a routerLink="/partners">{{Partners}}</a></li>
                            <li>
                                <a routerLink="/clients">{{Clients}}</a></li>

                            <li>
                             <a routerLink="/contactUs">{{contactUs}}</a>
                            </li>


                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-footer-text ttm-textcolor-white">
        <div class="container">
            <div class="row copyright">
                <div class="col-md-6 col-sm-12 copyright_L">
                    <span>
                        © {{currentYear}} All rights reserved. 
                    </span>
                    <a  class="onhover" target="_blank" href="https://newtouch.dev/"> <span>
                        Powered by NewTouch Smart Technology Solutions
                    </span>
                    </a>
                </div>
                <div class="col-md-6 col-sm-12 copyright_R">
                    <span >
                        © {{currentYear}} كافة الحقوق محفوظة. 
                    </span>
                    <a class="onhover" target="_blank" href="https://newtouch.dev/"> <span>
                        نيوتاتش للحلول التقنية الذكية
                    </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>


<a id="totop" href="#top">
    <i class="fa fa-angle-up"></i>
</a>
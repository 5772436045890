import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
// import { NgxPaginationModule } from 'ngx-pagination';
// import { AlertModule } from 'ngx-alerts';
import { LoadingModules } from "./components/loading/loading.module";


@NgModule({
  declarations: [
    AppComponent
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    // NgxPaginationModule,
    // AlertModule.forRoot({maxMessages: 5, timeout: 5000,positionX:'right'})  ,
    LoadingModules
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

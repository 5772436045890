import { DOCUMENT } from '@angular/common';
import { OnInit } from '@angular/core';
import { Component, ElementRef, Inject } from '@angular/core';
import { HomeService } from './services/home.service';
import { servicesUrl } from './services/servicesUrl';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  load: boolean = false;
  varLang: boolean = false;
  currentYear: number = new Date().getFullYear();

  home: string = '';
  aboutUs: string = '';
  AboutDetials: string = '';
  AboutTitle: string = '';
  AboutSub: string = '';
  titleCompany: string = '';
  Events: string = '';
  aboutCampay: string = '';
  products: string = '';
  advertisement: string = '';
  News: string = '';
  contactUs: string = '';
  location: string = '';
  Phone: string = '';
  Mobile: string = '';
  Email: string = '';
  SiteMap: string = '';
  SocialMedia: string = '';
  Languages: string = '';
  services: string = '';
  statistics: string = '';
  Partners: string = '';
  Clients: string = '';
  developedBy: string = '';
  address_: string = '';
  address2_: string = '';
  email__: string = '';
  email2__: string = '';
  ph1: string = '';
  ph2: string = '';
  facebook = '';
  twitter = '';
  linkdin = '';
  pinterest = '';
  youtube = '';
  Image = '';
  imgUrl = '';
  instgram = '';
  arrayFeaturs: any;
  arrayAboutUs: any;
  arrayNews: any;
  BusinessLine = '';
  Quality = '';

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private elementRef: ElementRef,
    private homeService_: HomeService,
    private ServiceUrl_: servicesUrl
  ) {}

  ngOnInit(): void {
     this.load = false;

    var s5 = document.createElement('script');
    s5.type = 'text/javascript';
    s5.id = 'JsMainJsA';
    s5.src = '../../../assets/js/main.js';
    this.elementRef.nativeElement.appendChild(s5);

    this.imgUrl = this.ServiceUrl_.ImgUrl;

    if (localStorage.getItem('lang') == null) {
      this.homeService_.getLangIsMain().subscribe((dataJson) => {
        localStorage.setItem('lang', dataJson[0]['LangId']);
        if (dataJson[0]['LangDir'] == 'RTL') {
          this.varLang=true;
          let link1: HTMLLinkElement = this.doc.createElement('link');
          link1.setAttribute('rel', 'stylesheet');
          link1.id = 'langfilewebsites';
          link1.setAttribute('href', '../assets/css/lang.css');
          this.doc.head.appendChild(link1);
        } else {
          this.varLang=false
          this.doc.getElementById('langfilewebsites')?.remove();
        }
        //contact data
        this.homeService_
          .getData(
            'getPost',
            dataJson[0]['LangId'],
            '',
            'ContactData',
            'NTROOT0',
            '',
            '1',
            ''
          )
          .subscribe((data) => {
         //    console.log('ContactData', data);
            let arr = data as any[];
            this.address_ = arr[0]['adress1'];
            this.address2_ = arr[0]['adress2'];
            this.email__ = arr[0]['email1'];
            // this.email2__ = arr[0]['email2'];
            this.ph1 = arr[0]['phone1'];
            this.ph2 = arr[0]['phone2'];
            this.facebook = arr[0]['facebook'];
            this.twitter = arr[0]['tweeter'];
            this.linkdin = arr[0]['linkedin'];
            this.pinterest = arr[0]['pinterest'];
            this.youtube = arr[0]['youtube'];
            this.instgram = arr[0]['instgram'];

            // header
            this.homeService_
              .getData(
                'getPost',
                dataJson[0]['LangId'],
                '',
                'Header.Data',
                'NTROOT0',
                '',
                '1',
                ''
              )
              .subscribe((data) => {
                //  console.log('header', data);
                let arr = data as any[];
                this.Image = this.imgUrl + arr[0]['postImage'];

                this.homeService_
                  .getData(
                    'getPostDetails',
                    dataJson[0]['LangId'],
                    '',
                    'Footer.Data',
                    'NTROOT0',
                    '',
                    '1',
                    ''
                  )
                  .subscribe((data) => {
                    let arr = data as any[];
                    // this.AboutDetials = arr[0]['postDetails'];
                    this.AboutTitle = arr[0]['postTitle'];
                    this.AboutSub = arr[0]['postSubTitle'];

                    // Featurs
                    this.homeService_
                      .getData(
                        'getPost',
                        dataJson[0]['LangId'],
                        '',
                        'Featurs',
                        'NTROOT0',
                        '',
                        '3',
                        ''
                      )
                      .subscribe((data) => {
                        this.arrayFeaturs = data;
                        //   console.log("Featurs",data);

                        //About us
                        this.homeService_
                          .getData(
                            'getPost',
                            dataJson[0]['LangId'],
                            '',
                            'About.Main',
                            'NTROOT0',
                            '',
                            '-1',
                            ''
                          )
                          .subscribe((data) => {
                            this.arrayAboutUs = data;
                            //  console.log("about",data);

                            this.homeService_
                              .getData(
                                'getPost',
                                dataJson[0]['LangId'],
                                '',
                                'News',
                                'NTROOT0',
                                '',
                                '3',
                                ''
                              )
                              .subscribe((data) => {
                                this.arrayNews = data;
                      //  console.log("News",this.arrayNews);
                       
                                this.homeService_
                                  .getLangJson(dataJson[0]['LangId'])
                                  .subscribe((data) => {
                                    this.home =
                                      data[0]['HeaderAndFooter']['Home'];
                                    this.titleCompany =
                                      data[0]['Home']['titleCompany'];

                                    this.aboutUs =
                                      data[0]['HeaderAndFooter']['aboutUs'];
                                    this.Events = data[0]['Home']['Events'];
                                    this.aboutCampay =
                                      data[0]['HeaderAndFooter']['aboutCampay'];
                                    this.products =
                                      data[0]['HeaderAndFooter']['products'];
                                    this.advertisement =
                                      data[0]['HeaderAndFooter'][
                                        'advertisement'
                                      ];
                                    this.News =
                                      data[0]['HeaderAndFooter']['News'];
                                    this.contactUs =
                                      data[0]['HeaderAndFooter']['contactUs'];
                                    this.location =
                                      data[0]['HeaderAndFooter']['location'];
                                    this.Phone =
                                      data[0]['HeaderAndFooter']['Phone'];
                                    this.Mobile =
                                      data[0]['HeaderAndFooter']['Mobile'];
                                    this.Email =
                                      data[0]['HeaderAndFooter']['Email'];
                                    this.SiteMap =
                                      data[0]['HeaderAndFooter']['SiteMap'];
                                    this.SocialMedia =
                                      data[0]['HeaderAndFooter']['SocialMedia'];
                                    this.Languages =
                                      data[0]['HeaderAndFooter']['Languages'];
                                    this.services =
                                      data[0]['HeaderAndFooter']['services'];
                                    this.statistics =
                                      data[0]['HeaderAndFooter']['statistics'];
                                    this.Partners =
                                      data[0]['HeaderAndFooter']['Partners'];
                                    this.Clients =
                                      data[0]['HeaderAndFooter']['Clients'];
                                    this.developedBy =
                                      data[0]['HeaderAndFooter']['developedBy'];
                                    this.BusinessLine =
                                      data[0]['HeaderAndFooter']['Categries'];
                                    this.Quality =
                                      data[0]['HeaderAndFooter']['Quality'];

                                    this.load = true;
                                  });
                              });
                          });
                      });
                  });
              });
          });
      });
    } else {
      if(String(localStorage.getItem('lang'))=='Ar'){
        this.varLang=true
      }
      else{
        this.varLang=false
      }
      this.homeService_
        .getLangWhereIdLang(String(localStorage.getItem('lang')))
        .subscribe((data) => {
          if (data[0]['LangDir'] == 'RTL') {
            let link1: HTMLLinkElement = this.doc.createElement('link');
            link1.setAttribute('rel', 'stylesheet');
            link1.id = 'langfilewebsites';
            link1.setAttribute('href', '../assets/css/lang.css');
            this.doc.head.appendChild(link1);
          } else {
            this.doc.getElementById('langfilewebsites')?.remove();
          }
        });

      // contact data
      this.homeService_
        .getData(
          'getPost',
          String(localStorage.getItem('lang')),
          '',
          'ContactData',
          'NTROOT0',
          '',
          '1',
          ''
        )
        .subscribe((data) => {
          let arr = data as any[];
              this.address_ = arr[0]['adress1'];
              this.address2_ = arr[0]['adress2'];
              this.email__ = arr[0]['email1'];
              // this.email2__ = arr[0]['email2'];
              this.ph1 = arr[0]['phone1'];
              this.ph2 = arr[0]['phone2'];
              this.facebook = arr[0]['facebook'];
              this.twitter = arr[0]['tweeter'];
              this.linkdin = arr[0]['linkedin'];
              this.pinterest = arr[0]['pinterest'];
              this.youtube = arr[0]['youtube'];
              this.instgram = arr[0]['instgram'];

          this.homeService_
            .getData(
              'getPost',
              String(localStorage.getItem('lang')),
              '',
              'News',
              'NTROOT0',
              '',
              '3',
              ''
            )
            .subscribe((data) => {
              this.arrayNews = data;
              // console.log("News",this.arrayNews);

              // header
              this.homeService_
                .getData(
                  'getPost',
                  String(localStorage.getItem('lang')),
                  '',
                  'Header.Data',
                  'NTROOT0',
                  '',
                  '1',
                  ''
                )
                .subscribe((data) => {
                  // console.log('header', data);
                  let arr = data as any[];
                  this.Image = this.imgUrl + arr[0]['postImage'];

                  this.homeService_
                    .getData(
                      'getPostDetails',
                      String(localStorage.getItem('lang')),
                      '',
                      'Footer.Data',
                      'NTROOT0',
                      '',
                      '1',
                      ''
                    )
                    .subscribe((data) => {
                      let arr = data as any[];
                      // this.AboutDetials = arr[0]['postDetails'];
                      this.AboutTitle = arr[0]['postTitle'];
                      this.AboutSub = arr[0]['postSubTitle'];

                      //Featurs
                      this.homeService_
                        .getData(
                          'getPost',
                          String(localStorage.getItem('lang')),
                          '',
                          'Featurs',
                          'NTROOT0',
                          '',
                          '3',
                          ''
                        )
                        .subscribe((data) => {
                          this.arrayFeaturs = data;
                          //  console.log("Featurs",data);

                          //About us
                          this.homeService_
                            .getData(
                              'getPost',
                              String(localStorage.getItem('lang')),
                              '',
                              'About.Main',
                              'NTROOT0',
                              '',
                              '-1',
                              ''
                            )
                            .subscribe((data) => {
                              this.arrayAboutUs = data;
                              //  console.log("about",data);

                              this.homeService_
                                .getLangJson(
                                  String(localStorage.getItem('lang'))
                                )
                                .subscribe((data) => {
                                  this.home =
                                    data[0]['HeaderAndFooter']['Home'];
                                  this.titleCompany =
                                    data[0]['Home']['titleCompany'];
                                  this.aboutUs =
                                    data[0]['HeaderAndFooter']['aboutUs'];
                                  this.Events = data[0]['Home']['Events'];
                                  this.aboutCampay =
                                    data[0]['HeaderAndFooter']['aboutCampay'];
                                  this.products =
                                    data[0]['HeaderAndFooter']['products'];
                                  this.advertisement =
                                    data[0]['HeaderAndFooter']['advertisement'];
                                  this.News =
                                    data[0]['HeaderAndFooter']['News'];
                                  this.contactUs =
                                    data[0]['HeaderAndFooter']['contactUs'];
                                  this.location =
                                    data[0]['HeaderAndFooter']['location'];
                                  this.Phone =
                                    data[0]['HeaderAndFooter']['Phone'];
                                  this.Mobile =
                                    data[0]['HeaderAndFooter']['Mobile'];
                                  this.Email =
                                    data[0]['HeaderAndFooter']['Email'];
                                  this.SiteMap =
                                    data[0]['HeaderAndFooter']['SiteMap'];
                                  this.SocialMedia =
                                    data[0]['HeaderAndFooter']['SocialMedia'];
                                  this.Languages =
                                    data[0]['HeaderAndFooter']['Languages'];
                                  this.services =
                                    data[0]['HeaderAndFooter']['services'];
                                  this.statistics =
                                    data[0]['HeaderAndFooter']['statistics'];
                                  this.Partners =
                                    data[0]['HeaderAndFooter']['Partners'];
                                  this.Clients =
                                    data[0]['HeaderAndFooter']['Clients'];
                                  this.developedBy =
                                    data[0]['HeaderAndFooter']['developedBy'];
                                  this.BusinessLine =
                                    data[0]['HeaderAndFooter']['Categries'];
                                  this.Quality =
                                    data[0]['HeaderAndFooter']['Quality'];

                                    this.load = true;
                                });
                            });
                        });
                    });
                });
            });
        });
    }
  }

  onActivate(event: any) {
    window.scroll(0, 0);
  }

  lang(lang: string): void {
    if(lang=='Ar'){
      this.varLang=true
    }
    else{
      this.varLang=false
    }
    localStorage.setItem('lang', lang);
    this.homeService_.getLangWhereIdLang(lang).subscribe((data) => {
      if (data[0]['LangDir'] == 'RTL') {
        let link1: HTMLLinkElement = this.doc.createElement('link');
        link1.setAttribute('rel', 'stylesheet');
        link1.id = 'langfilewebsites';
        link1.setAttribute('href', '../assets/css/lang.css');
        this.doc.head.appendChild(link1);
      } else {
        this.doc.getElementById('langfilewebsites')?.remove();
      }
    });
    location.replace('');
  }
}

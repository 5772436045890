import { LoadingComponent } from './loading.component';
import { NgModule} from "@angular/core";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [LoadingComponent],
    exports:[LoadingComponent]
})
export class LoadingModules { }
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./components/home/home.module').then((m) => m.homeModules),
  },

  {
    path: 'home',
    loadChildren: () =>
      import('./components/home/home.module').then((m) => m.homeModules),
  },
  {
    path: 'services',
    loadChildren: () =>
      import('./components/services/services.module').then(
        (m) => m.ServicesModules
      ),
  },
  {
    path: 'statistic',
    loadChildren: () =>
      import('./components/statistic/statistic.module').then(
        (m) => m.StatisticModules
      ),
  },
  {
    path: 'Features',
    loadChildren: () =>
      import('./components/features/feature.module').then(
        (m) => m.featureModules
      ),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./components/about/about.module').then((m) => m.aboutModules),
  },
  {
    path: 'about/:id',
    loadChildren: () =>
      import('./components/about/about.module').then((m) => m.aboutModules),
  },
  // {
  //   path: 'events',
  //   loadChildren: () => import('./components/events/event.module').then(m => m.EventModules)
  // },
  // {
  //   path: 'events/:id',
  //   loadChildren: () => import('./components/events-details/event-details.module').then(m => m.EventDetailsModules),
  //   runGuardsAndResolvers: 'always'
  // },
  {
    path: 'news',
    loadChildren: () =>
      import('./components/news/news.module').then((m) => m.newsModules),
  },
  {
    path: 'news/:id',
    loadChildren: () =>
      import('./components/news-details/news-detial.module').then(
        (m) => m.newsDetialModules
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'partners',
    loadChildren: () =>
      import('./components/partners/partner.module').then(
        (m) => m.partnerModules
      ),
  },
  {
    path: 'clients',
    loadChildren: () =>
      import('./components/clients/client.module').then((m) => m.clientModules),
  },
  {
    path: 'contactUs',
    loadChildren: () =>
      import('./components/contact-us/contact.module').then(
        (m) => m.contactModules
      ),
  },
  // {
  //   path: 'ourTeam',
  //   loadChildren: () => import('./components/team/team.module').then(m => m.TeamModules)
  // },
  {
    path: 'Accreditation',
    loadChildren: () =>
      import('./components/team/team.module').then((m) => m.TeamModules),
  },
  // {
  //   path: 'ourTeam/:id',
  //   loadChildren: () => import('./components/tteam-detials/team-detials.module').then(m => m.TeamDetialsModules),
  //   runGuardsAndResolvers: 'always'
  // },
  {
    path: 'Details/:type/:id',
    loadChildren: () =>
      import('./components/tteam-detials/team-detials.module').then(
        (m) => m.TeamDetialsModules
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'PhotoBrowser',
    loadChildren: () =>
      import('./components/photo-browser/photo.module').then(
        (m) => m.photoModules
      ),
  },
  {
    path: 'Quality',
    loadChildren: () =>
      import('./components/quality/quality.module').then(
        (m) => m.QualityModules
      ),
  },
  {
    path: 'ProductCategories',
    loadChildren: () =>
      import('./components/product-categories/product-categories.module').then(
        (m) => m.productCategoriesModules
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'Products/:id',
    loadChildren: () =>
      import('./components/products/products.module').then(
        (m) => m.productsModules
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'Products/:parent/:id',
    loadChildren: () =>
      import('./components/products-detials/products-detials.module').then(
        (m) => m.productsDetialsModules
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'Projects',
    loadChildren: () =>
      import('./components/projects/projects.module').then(
        (m) => m.ProjectsModule
      ),
  },

  {
    path: '404',
    loadChildren: () =>
      import('./components/error/error.module').then((m) => m.ErrorModules),
  },
  {
    path: 'HomeAbout',
    loadChildren: () =>
      import('./components/home/components/home-about/home-about.module').then(
        (m) => m.HomeAboutModule
      ),
  },
  {
    path: 'productCat',
    loadChildren: () =>
      import(
        './components/home/components/home-products-cat/home-products-cat.module'
      ).then((m) => m.HomeProductsCatModule),
  },
  {
    path: 'HomeNews',
    loadChildren: () =>
      import('./components/home/components/home-news/home-news.module').then(
        (m) => m.HomeNewsModule
      ),
  },
  // {
  //   path: 'HomeQualitys',
  //   loadChildren: () =>
  //     import(
  //       './components/home/components/home-quality/home-quality.module'
  //     ).then((m) => m.HomeQualityModule),
  // },
  {
    path: 'HomeProjects',
    loadChildren: () =>
      import(
        './components/home/components/home-projects/home-projects.module'
      ).then((m) => m.HomeProjectsModule),
  },
  {
    path: 'HomeTeam',
    loadChildren: () =>
      import('./components/home/components/home-team/home-team.module').then(
        (m) => m.HomeTeamModule
      ),
  },
  {
    path: 'HomeClients',
    loadChildren: () =>
      import(
        './components/home/components/home-clients/home-clients.module'
      ).then((m) => m.HomeClientsModule),
  },
  {
    path: 'HomePartners',
    loadChildren: () =>
      import(
        './components/home/components/home-partners/home-partners.module'
      ).then((m) => m.HomePartnersModule),
  },
  {
    path: 'HomeStatistic',
    loadChildren: () =>
      import(
        './components/home/components/home-statistic/home-statistic.module'
      ).then((m) => m.HomeStatisticModule),
  },
  {
    path: 'HomeAccreditation',
    loadChildren: () =>
      import(
        './components/home/components/home-accreditation/home-accreditation.module'
      ).then((m) => m.HomeAccreditationModule),
  },
  { path: 'HomeGallery', loadChildren: () => import('./components/home/components/home-gallery/home-gallery.module').then(m => m.HomeGalleryModule) },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
